<template>
  <section class="m-setting m-scroll">
    <!-- 头部导航 -->
    <m-header title="设置" ></m-header>

    <div class="main">
      <router-link :to="account.is_phone == 1 || account.is_email == 1 ? '/zh/setting/user' : $route.fullPath"  class="setting-cell van-hairline--bottom"
      @click.native="userinfoTip">
        <div class="label">个人信息</div>
        <div class="right">
          <img class="avatar" :src="$route.query.avatar == '' ? $store.state.avatar :  $route.query.avatar" alt="avatar">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="">
        </div>
      </router-link>

      <router-link to="/zh/setting/security"  class="setting-cell van-hairline--bottom">
        <div class="label">账户与安全</div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="">
        </div>
      </router-link>

      <router-link to="/zh/setting/terms"  class="setting-cell van-hairline--bottom">
        <div class="label">条款</div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="">
        </div>
      </router-link>

      <router-link to="/zh/setting/clause/about"  class="setting-cell">
        <div class="label">关于MOOBY</div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="">
        </div>
      </router-link>
    </div>

    <div class="footer">
      <van-button @click="handleSignOut" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">退出当前账号 </van-button>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

import { userBind } from '@/api/zh/mine.js'
export default {
  name:'Setting',
  components:{ MHeader },
  data() {
    return {
      account: {
        is_email: 0,
        is_faceid: 0,
        is_instaid: 0,
        is_phone: 0,
        is_wx: 0,
      }
    }
  },
  methods:{
    // 退出当前账号
    handleSignOut(){
      localStorage.setItem('token', null)
      this.$router.push({path:'/zh/login'})
    },
    // 获取绑定信息
    getBind() {
      userBind().then(res => {
        if(res) {
          this.account = res.data
        }
      })
    },
    userinfoTip() {
      if(this.account.is_phone == 0 && this.account.is_email == 0) {
        this.$notify({ type: 'primary', message: '请先绑定手机号或者邮箱' })
      }
    }
  },
  created() {
    this.getBind()
  }
}
</script>

<style lang="less" scoped>
@import './setting.less';
</style>